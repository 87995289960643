import React, { useState } from 'react';
import '../../assets/css/styles.css';

const listingsData = [
  {
    id: 1,
    title: "Luxury High-Rise Apartment with Stunning City Views in New York City",
    price: "$1,200,000",
    images: [
      require('../../assets/img/listings/listing1.jpg'),
      require('../../assets/img/listings/listing1_1.jpg'),
      require('../../assets/img/listings/listing1_2.jpg'),
    ],
  },
  {
    id: 2,
    title: "Serene Villa Nestled in the Hills of Tuscany",
    price: "$8,500,000",
    images: [
      require('../../assets/img/listings/listing2.jpg'),
      require('../../assets/img/listings/listing2_1.jpg'),
      require('../../assets/img/listings/listing2_2.jpg'),
    ],
  },
  {
    id: 3,
    title: "Modern Retreat in Malibu",
    price: "$3,500,000",
    images: [
      require('../../assets/img/listings/listing3.jpg'),
      require('../../assets/img/listings/listing3_1.jpg'),
      require('../../assets/img/listings/listing3_2.jpg'),
      require('../../assets/img/listings/listing3_3.jpg')
    ],
  },
  {
    id: 4,
    title: "Spacious Family Home in Malibu",
    price: "$4,500,000",
    images: [
      require('../../assets/img/listings/listing4.jpg'),
      require('../../assets/img/listings/listing4_1.jpg'),
      require('../../assets/img/listings/listing4_2.jpg'),
      require('../../assets/img/listings/listing4_3.jpg')
    ],
  },
  {
    id: 5,
    title: "Charming Cottage in Delaware",
    price: "$1,200,000",
    images: [
      require('../../assets/img/listings/listing5.jpg'),
      require('../../assets/img/listings/listing5_1.jpg'),
    ],
  },
  {
    id: 6,
    title: "Elegant Coastal Carolina Estate with Panoramic Views",
    price: "$3,500,000",
    images: [
      require('../../assets/img/listings/listing6.jpg'),
      require('../../assets/img/listings/listing6_1.jpg'),
      require('../../assets/img/listings/listing6_2.jpg'),
    ],
  },
  {
    id: 7,
    title: "Edinburgh Apartment with Luxe Amenities",
    price: "$1,150,000",
    images: [
      require('../../assets/img/listings/listing7.jpg'),
      require('../../assets/img/listings/listing7_1.jpg'),
    ],
  },
  {
    id: 8,
    title: "Idaho Country Estate with 51 Acres",
    price: "$9,500,000",
    images: [
      require('../../assets/img/listings/listing8.jpg'),
      require('../../assets/img/listings/listing8_1.jpg'),
    ],
  },
  {
    id: 9,
    title: "Cozy Retreat in Secluded New England Community",
    price: "$2,500,000",
    images: [
      require('../../assets/img/listings/listing9.jpg'),
      require('../../assets/img/listings/listing9_1.jpg'),
    ],
  },
  {
    id: 10,
    title: "Long Island Family Home",
    price: "$600,000",
    images: [
      require('../../assets/img/listings/listing10.jpg'),
      require('../../assets/img/listings/listing10_1.jpg'),
      require('../../assets/img/listings/listing10_2.jpg'),
    ],
  },
  {
    id: 11,
    title: "Orange County 3 Bedroom",
    price: "$800,000",
    images: [
      require('../../assets/img/listings/listing11.jpg'),
      require('../../assets/img/listings/listing11_1.jpg'),
    ],
  },
];


const Speakeasy = () => {
  const [searchFilters, setSearchFilters] = useState({
    country: '',
    state: '',
    propertyType: '',
    priceRange: ''
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(
    listingsData.map(() => 0) // For each listing, start at the first image
  );

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Logic for searching listings with the searchFilters state
    console.log('Search Filters:', searchFilters);
  };

  const handleImageNavigation = (listingIndex, direction) => {
    setCurrentImageIndex((prevIndexes) => {
      const newIndexes = [...prevIndexes];
      newIndexes[listingIndex] =
        (newIndexes[listingIndex] + direction + listingsData[listingIndex].images.length) %
        listingsData[listingIndex].images.length;
      return newIndexes;
    });
  };

  return (
    <div className="speakeasy-container">
      {/* Title and Motto */}
      <header className="speakeasy-header">
        <h1>The Best Secret Real Estate Listings</h1>
        <p className="motto">"Here you'll find what you can't find anywhere else."</p>
      </header>

      {/* Search Bar */}
      <div className="search-section">
        <h2>Find Your Secret Listing</h2>
        <form onSubmit={handleSearchSubmit} className="search-form">
          <div className="search-field">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              id="country"
              name="country"
              value={searchFilters.country}
              onChange={handleSearchChange}
              placeholder="Enter country"
            />
          </div>
          <div className="search-field">
            <label htmlFor="state">State/Region:</label>
            <input
              type="text"
              id="state"
              name="state"
              value={searchFilters.state}
              onChange={handleSearchChange}
              placeholder="Enter state or region"
            />
          </div>
          <div className="search-field">
            <label htmlFor="propertyType">Property Type:</label>
            <input
              type="text"
              id="propertyType"
              name="propertyType"
              value={searchFilters.propertyType}
              onChange={handleSearchChange}
              placeholder="e.g., Apartment, Villa, House"
            />
          </div>
          <div className="search-field">
            <label htmlFor="priceRange">Price Range:</label>
            <input
              type="text"
              id="priceRange"
              name="priceRange"
              value={searchFilters.priceRange}
              onChange={handleSearchChange}
              placeholder="e.g., $200,000 - $500,000"
            />
          </div>
          <button type="submit" className="cta-button">Search Listings</button>
        </form>
      </div>

      {/* Listing Section */}
      <div className="listings-section">
        <h2>Secret Listings</h2>
        <div className="listings-grid">
          {listingsData.map((listing, listingIndex) => (
            <div className="listing-card" key={listing.id}>
              <div className="image-carousel">
                <button
                  className="carousel-button prev"
                  onClick={() => handleImageNavigation(listingIndex, -1)}
                >
                  &lt;
                </button>
                <img
                  src={listing.images[currentImageIndex[listingIndex]]}
                  alt={`Listing ${listingIndex + 1}`}
                />
                <button
                  className="carousel-button next"
                  onClick={() => handleImageNavigation(listingIndex, 1)}
                >
                  &gt;
                </button>
              </div>
              <h3>{listing.title}</h3>
              <p>Tentative Price: {listing.price}</p>
              <button className="cta-button">View Details</button>
            </div>
          ))}
        </div>
      </div>
      {/* Reviews Section */}
      <div className="reviews-section">
        <h2>What Our Happy Real Estate Fans Say</h2>

        <div className="review">
          <p>"I never thought I’d find a penthouse in NYC with a view like this! The secret's out now—thanks, FansSecret!"</p>
          <p><strong>- UrbanExplorer92</strong></p>
        </div>

        <div className="review">
          <p>"This site found me a villa in Tuscany that I swear doesn't even exist on Google Maps. It's like it was built just for me!"</p>
          <p><strong>- PastaAndProsecco</strong></p>
        </div>

        <div className="review">
          <p>"My beachfront house in Malibu was so well-hidden that even the neighbors didn’t know it existed. Thanks, FansSecret for keeping my home a mystery!"</p>
          <p><strong>- WaveRider007</strong></p>
        </div>

        <div className="review">
          <p>"Who knew I could find a mountain cabin with zero cell reception, 100% seclusion? Perfect for my tech detox retreats. Highly recommend!"</p>
          <p><strong>- DigitalDetoxDan</strong></p>
        </div>

        <div className="review">
          <p>"I’ve bought so many hidden properties through FansSecret, I’m starting to feel like a real estate ninja."</p>
          <p><strong>- HouseHustler</strong></p>
        </div>

        <div className="review">
          <p>"My friends laughed when I said I was moving to a hidden mansion in Beverly Hills. Who’s laughing now?!"</p>
          <p><strong>- SneakyBuyer123</strong></p>
        </div>

        <div className="review">
          <p>"Let’s just say my new 'undisclosed location' isn’t on any map—and that’s exactly how I like it. Privacy goals achieved!"</p>
          <p><strong>- GhostHouse</strong></p>
        </div>

        <div className="review">
          <p>"FansSecret made finding my dream farmhouse in France easy. Now, I just need to learn French... maybe they have a secret language school too?"</p>
          <p><strong>- BonjourBaguette</strong></p>
        </div>

        <div className="review">
          <p>"The listings here are so exclusive, I think I just bought a property that doesn’t even exist. Still waiting for the key, though."</p>
          <p><strong>- RiskyBusiness</strong></p>
        </div>

        <div className="review">
          <p>"If you like your privacy and don’t mind a secret cave mansion in the Swiss Alps, this is the site for you. Just bring a coat."</p>
          <p><strong>- SnowedInSteve</strong></p>
        </div>
      </div>


    </div>
  );
};

export default Speakeasy;
