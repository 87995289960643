import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Correct import
import { AuthContext } from 'provider/auth-provider';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const { setIsLoggedIn, setToken, setSubscription, setLoading } = useContext(AuthContext);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || 'Login failed. Please try again.');
        setLoading(false);
        return;
      }

      const data = await response.json();
      if (data.success && data.token) {
        toast.success('Successfully logged in.');
        localStorage.setItem('token', data.token);
        setToken(data.token);
        setIsLoggedIn(true);

        try {
          const decoded = jwtDecode(data.token);
          setSubscription(decoded.subscription);
        } catch (err) {
          console.error('Failed to decode token:', err);
          toast.error('Invalid token received.');
          setLoading(false);
          return;
        }
        navigate('/subscription'); // Redirect to settings page
      } else {
        toast.error(data.message || 'Invalid login. Please try again.');
      }
    } catch (error) {
      toast.error('Login failed. Please try again.');
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const onGoogleSuccess = async (response) => {
    try {
      setLoading(true);

      console.log('Google response token:', response.credential); // Debug log

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/google-auth`,
        {
          token: response.credential,
        }
      );

      console.log('Backend response data:', data); // Debug log

      if (data.success) {
        toast.success('Successfully logged in.');
        localStorage.setItem('token', data.token);
        setToken(data.token);
        setIsLoggedIn(true);
        const decoded = jwtDecode(data.token); // Ensure jwtDecode is imported correctly
        setSubscription(decoded.subscription);
        navigate('/subscription');  // Ensure the route is correctly set up
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Google login failed. Please try again.');
      console.error('Google login error:', error.response ? error.response.data : error.message); // Enhanced error logging
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='form-container'>
      <div className='form-box'>
        <h2>Login</h2>
        <form className='login-form' onSubmit={handleLogin}>
          <div className='form-group'>
            <label htmlFor='email'>Email:</label>
            <input
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password:</label>
            <input
              type='password'
              id='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <button type='submit' ref={buttonRef} id='loginButton'>
              Login
            </button>
          </div>
          <div className='form-group google-login'>
            <div className='divider'></div>
            <GoogleLogin
              onSuccess={onGoogleSuccess}
              onError={() => console.log('Login Failed')}
              width={buttonWidth}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
