import React, { useState, useCallback, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { TailSpin } from "react-loader-spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "../../assets/css/WatermarkPage.css";
import svg from "assets/img/cloud-upload-regular-240.png";
import videoContractPDF from '../../assets/docs/Video_Contract.pdf';


const baseUrl = "http:localhost:4000";

Modal.setAppElement("#root");

function WaterMarkPage() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState("https://example.com/forensic-watermarked-video.mp4");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    authors: "",
    yearOfBirth: "",
    citizenship: "",
    name: "",
    address: "",
    yearOfCreation: "",
    socialMedia: "",  
  });

  const [copied, setCopied] = useState(false);
  const [minDob, setMinDob] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [isOver, setIsOver] = useState(false);
  const fileInputRef = useRef(null);

  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    const today = new Date();
    const minAge = 18;
    const minDob = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
      .toISOString()
      .split("T")[0];
    setMinDob(minDob);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setVideoFile(files[0]);
      if (fileInputRef.current) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(files[0]);
        fileInputRef.current.files = dataTransfer.files;
      }
    }
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    openModal();
    const data = new FormData();
    data.append("video", videoFile);
    data.append("formData", JSON.stringify(formData));
    setVideoFile(null);
    setFormData({
      title: "",
      authors: "",
      yearOfBirth: "",
      citizenship: "",
      name: "",
      address: "",
      yearOfCreation: "",
    });
    document.querySelector('input[type="file"]').value = "";
    try {
      const response = await axios.post("http://localhost:4000/api/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUrlToCopy(response.data.location);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
    setLoading(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openPdfModal = () => {
    setPdfModalIsOpen(true);
  };

  const closePdfModal = () => {
    setPdfModalIsOpen(false);
  };

  const handleTermsCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
    if (e.target.checked) {
      openPdfModal();
    }
  };

  const handleLinkCopy = () => setCopied(true);

  return (
    <>
      <div className="account-page">
        <main className="main">
          <div className="container">
            <h1 className="title">Upload and Watermark Video</h1>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-group work-title">
                <label className="label">Title of the Work:</label>
                <input
                  className="input"
                  type="text"
                  name="title"
                  onChange={handleInputChange}
                  value={formData.title}
                  required
                />
              </div>
              <div className="form-group">
                <label className="label">Name of the Author(s):</label>
                <input
                  className="input"
                  type="text"
                  name="authors"
                  onChange={handleInputChange}
                  required
                  value={formData.authors}
                />
              </div>
              <div className="form-group">
                <label className="label">Year of Birth:</label>
                <input
                  className="input"
                  type="date"
                  max={minDob}
                  name="yearOfBirth"
                  onChange={handleInputChange}
                  required
                  value={formData.yearOfBirth}
                />
              </div>
              <div className="form-group">
                <label className="label">Citizenship and Domicile:</label>
                <input
                  className="input"
                  type="text"
                  name="citizenship"
                  onChange={handleInputChange}
                  required
                  value={formData.citizenship}
                />
              </div>
              <div className="form-group">
                <label className="label">Artist Name:</label>
                <input
                  className="input"
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  required
                  value={formData.name}
                />
              </div>
              <div className="form-group">
                <label className="label">Address:</label>
                <input
                  className="input"
                  type="text"
                  name="address"
                  onChange={handleInputChange}
                  required
                  value={formData.address}
                />
              </div>
              <div className="form-group">
                <label className="label">Year of Creation:</label>
                <input
                  className="input"
                  type="date"
                  max={`${new Date().toISOString().split("T")[0]}`}
                  name="yearOfCreation"
                  onChange={handleInputChange}
                  required
                  value={formData.yearOfCreation}
                />
              </div>
            <div className="form-group socialMedia">
            <label className="label">Where to Find You on Social Media:</label>
            <input
              className="input"
              type="text"
              name="socialMedia"
              onChange={handleInputChange}
              value={formData.socialMedia || ""}
              required
            />
            </div>
              <div className="form-group uploader">
                <label className="label">Video File:</label>
                <div
                  className={`upload-div ${isOver ? "drag-over" : ""}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <img src={svg} alt="Upload Icon" />
                  <p>Drag and Drop here</p>
                  <p>OR</p>
                  <input
                    ref={fileInputRef}
                    className="input"
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <div className="form-group terms">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="acceptTerms"
                    required
                    onChange={handleTermsCheckboxChange}
                  
                  />
                  <label htmlFor="acceptTerms">
                    I agree to the Terms and Conditions
                  </label>
                </div>
              <button className="button" type="submit" disabled={!termsAccepted}>
                Upload and Watermark
              </button>
              </div>
              </div>
            </form>
          </div>
        </main>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Video Link Generated"
          style={{
            overlay: {

              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              textAlign: "center",
              position: "relative",
              inset: "auto", 
              margin: "auto", 
              width:"15%",
              height:"25%",
              top:"50%",
            },
          }}
        >
          {loading ? (
            <div className="loaderContainer">
              <TailSpin
                visible={loading}
                height="80"
                width="80"
                color="#4fa94d"
              />
              <p>In progress, please wait...</p>
            </div>
          ) : (
            <div>
              <h2>Video Link Generated</h2>
              <div className="url-container">
                <input
                  type="text"
                  value={urlToCopy}
                  readOnly
                  className="video-link-input"
                />
                <CopyToClipboard text={urlToCopy} onCopy={handleLinkCopy}>
                  <button>{copied ? "Copied!" : "Copy URL"}</button>
                </CopyToClipboard>
              </div>
              <button className="water-mark-close-button" onClick={closeModal}>
                Close
              </button>
            </div>
          )}
        </Modal>

        <Modal
          isOpen={pdfModalIsOpen}
          onRequestClose={closePdfModal}
          contentLabel="Terms and Conditions"
          style={{
            overlay: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              position: "relative",
              inset: "auto", 
              margin: "auto", 
              width: "80%",
              height: "80%",
              maxWidth: "800px", 
              maxHeight: "80%", 
              padding: "20px",
              overflow: "auto",
        
            },


          }}
        >
          <iframe
            src={videoContractPDF}
            className="terms-iframe"
            title="Terms and Conditions"
            style={{ width: "100%", height: "90%" }}
          ></iframe>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
    <button
      className="water-mark-close-button"
      onClick={closePdfModal}
      style={{
        padding: "10px 20px",
        backgroundColor: "#4fa94d",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  </div>
        </Modal>
      </div>
    </>
  );
}

export default WaterMarkPage;
