import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/styles.css';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <header className="header">
        <div className="logo-container">
          <img src="/assets/FS5.png" alt="Whiteshoe Logo" className="logo" />
          <span className="title">FansSecret</span>
        </div>
        <nav className="nav">
          <Link to="/login" className="login-button">Login</Link>
        </nav>
      </header>
      <main>
        {children}
      </main>
      <footer className="footer">
        <p>All rights reserved Web3 Services, LLC</p>

      </footer>
    </div>
  );
};

export default Layout;
