import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes, Route, Navigate,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthProvider, { AuthContext } from './provider/auth-provider';
import Layout from './components/layout/Layout';
import LoginPage from './components/auth/LoginPage';
import SignupPage from './components/auth/SignupPage';
import HomePage from './components/pages/HomePage';
import Speakeasy from './components/pages/Speakeasy'; // Capitalized 'Speakeasy'
import DocumentationPage from './components/pages/DocumentationPage';
import TermAndCondition from './components/common/TermAndCondition';
import PrivacyPolicy from './components/common/PrivacyPolicy';
import Video from './components/pages/Video';
import Water from './components/pages/WaterMarkPage';

import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Spinner from './components/common/Spinner';
import ComingPage from './components/pages/ComingPage';

const RoutesComponent = () => {
  const { subscription, isLoggedIn } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        {/* Speakeasy page for the root */}
        <Route path='/' element={<Layout><Speakeasy /></Layout>} />

        {/* HomePage at /creator path */}
        <Route path='/creator' element={<Layout><HomePage /></Layout>} />

        {/* Other routes wrapped in Layout */}
        <Route path='/documentation' element={<Layout><ComingPage /></Layout>} />
        <Route path='/termsofservice' element={<Layout><TermAndCondition /></Layout>} />
        <Route path='/privacypolicy' element={<Layout><PrivacyPolicy /></Layout>} />
        <Route
          path='/water'
          element={true ? <Layout><Water /></Layout> : <Navigate to='/login' />}
        />
        <Route path='/signup' element={<Layout><SignupPage /></Layout>} />
        <Route path='/login' element={<Layout><LoginPage /></Layout>} />
        <Route path='/video' element={<Layout><Video /></Layout>} />
      </Routes>
    </Router>
  );
};

const App = () => {
  const [googleClientId, setGoogleClientId] = useState('');

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/config`
        );
        setGoogleClientId(response.data.googleClientId);
      } catch (error) {
        console.error('Failed to fetch configuration:', error);
      }
    };
    fetchConfig();
  }, []);

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <AuthProvider>
        <Spinner />
        <ToastContainer />
        <RoutesComponent />
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
