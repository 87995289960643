import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Typing from 'react-typing-effect';
import '../../assets/css/styles.css';

const HomePage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popup, setPopup] = useState({
    visible: false,
    feature: null,
    details: '',
    position: { x: 0, y: 0 }
  });

  const paragraphText =
    "For too long, amateur content creators have faced privacy violations, content leaks, and unauthorized sharing. The industry's big players protect their private rights, but amateur creators are left vulnerable. FansSecret offers you the power to protect your work with cutting-edge technology. With forensic watermarking, web scanning, and legal enforcement, your content is safeguarded from theft. Get peace of mind knowing your rights are defended and that any unauthorized sharing will lead to legal action with real consequences. At FansSecret, we promise creators either PRIVACY or PAYMENT.";

    const comparisonData = [
      { feature: 'Invisible Watermarking', details: 'All content shared on FS has invisible watermarking that allows us to monitor the web and find out which users leaked content.', FansSecret: true, Onlyfans: false, Fansly: false, Manyvids: false, Patreon: false },
      { feature: 'Web Monitoring', details: 'FS monitors the web for any unauthorized sharing of content. Other sites do nothing and force creators to rely on third party services.', FansSecret: true, Onlyfans: false, Fansly: false, Manyvids: false, Patreon: false },
      { feature: 'Legal Enforcement', details: 'FS aggressively pursues infringers. On other platforms, the creators are forced to handle difficult legal process on their own. FS makes it simple.', FansSecret: true, Onlyfans: false, Fansly: false, Manyvids: false, Patreon: false },
      { feature: 'User Vetting', details: 'FS financially vets users. Only people who are able to pay infringement fees are allowed onto FS. That also means viewers will be people who are able to spend more on your content too.', FansSecret: true, Onlyfans: false, Fansly: false, Manyvids: false, Patreon: false },
      { feature: 'Infringement Payouts', details: 'If a user leaks your content, we would pursue and you would receive a payout of several thousand dollars at least. ', FansSecret: true, Onlyfans: false, Fansly: false, Manyvids: false, Patreon: false },
      { feature: 'Fan Platform Features', details: 'Like the other platforms, FS supports subscriptions, pay-per-view, feed, messaging, and other basic fansite features.', FansSecret: true, Onlyfans: true, Fansly: true, Manyvids: true, Patreon: true },
      { feature: 'Fee', details: 'FS takes a 20% fee on all reveneu received from your fans.', FansSecret: '20%', Onlyfans: '20%', Fansly: '20%', Manyvids: '20%', Patreon: '5-12%' }
    ];

  const handleInfoClick = (e, feature, details) => {
    setPopup({
      visible: true,
      feature: feature,
      details: details,
      position: { x: e.pageX, y: e.pageY }
    });
  };

  const handleClosePopup = () => {
    setPopup({
      visible: false,
      feature: null,
      details: '',
      position: { x: 0, y: 0 }
    });
  };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div className="hero-section">
        <div className="hero-overlay">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Private Fansite
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Protect your content. Make infringers pay.
          </motion.p>
          <motion.button
            className="cta-button"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handlePopupOpen}
          >
            Signup
          </motion.button>


        </div>
      </div>

      <div className="typewriter-section">
        <Typing
          text={paragraphText}
          speed={10}
          eraseDelay={1000000000} // Practically never erase
          startDelay={0} // Starts typing right away
        />
      </div>
      <div className="comparison-chart">
        <h2>FansSecret is different.</h2>
        <div className="chart-container">
          <motion.div
            className="chart-header"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
          >
            <div className="feature">Feature</div>
            <div className="check">
              <img src="/assets/FS3.png" alt="FansSecret" className="platform-logo fs-logo" />
            </div>
            <div className="check">
              <img src="/assets/OnlyFans.png" alt="OnlyFans" className="platform-logo" />
            </div>
            <div className="check">
              <img src="/assets/fansly.png" alt="Fansly" className="platform-logo" />
            </div>
            <div className="check">
              <img src="/assets/manyvids.png" alt="JustForFans" className="platform-logo" />
            </div>
            <div className="check">
              <img src="/assets/Patreon.png" alt="Patreon" className="platform-logo" />
            </div>
          </motion.div>

          {comparisonData.map((row, index) => (
            <motion.div
              key={index}
              className="chart-row"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 2, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <div className="feature">
                {row.feature}
                <span className="info-icon" onClick={(e) => handleInfoClick(e, row.feature, row.details)}>
                  ℹ️
                </span>
              </div>
              <div className="check">
                {typeof row.FansSecret === 'string' ? row.FansSecret : (row.FansSecret ? <div className="yes">Yes</div> : <div className="no">No</div>)}
              </div>
              <div className="check">
                {typeof row.Onlyfans === 'string' ? row.Onlyfans : (row.Onlyfans ? <div className="yes">Yes</div> : <div className="no">No</div>)}
              </div>
              <div className="check">
                {typeof row.Fansly === 'string' ? row.Fansly : (row.Fansly ? <div className="yes">Yes</div> : <div className="no">No</div>)}
              </div>
              <div className="check">
                {typeof row.Manyvids === 'string' ? row.Manyvids : (row.Manyvids ? <div className="yes">Yes</div> : <div className="no">No</div>)}
              </div>
              <div className="check">
                {typeof row.Patreon === 'string' ? row.Patreon : (row.Patreon ? <div className="yes">Yes</div> : <div className="no">No</div>)}
              </div>
            </motion.div>
          ))}

        </div>

        {popup.visible && (
          <motion.div
            className="info-popup"
            style={{ top: popup.position.y + 10, left: popup.position.x + 10 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onMouseLeave={handleClosePopup}
          >
            {popup.details}
          </motion.div>
        )}
      </div>



      {/* FAQ Section */}
      <div className="faq-pile">
        <div className="faq-circle" id="circle1">
          <div className="image-container">
            <img src="/assets/blob1.png" alt="Blob 1" className="blob-image" />
          </div>
          <div className="faq-text faq-front">How can you make sure my content isn't leaked?</div>
          <div className="faq-text faq-back">When users sign up, they promise to not leak any content. We make sure they are finanically capable to pay a penalty. If we find any content outside the platform, we can analyze the image or video and determine which user leaked it. We will then force that user to pay thousands of dollars in fines. While it is still possible that your content will be leaked, on FS at least you will be compensated for the leak.</div>
        </div>
        <div className="faq-circle" id="circle2">
          <div className="image-container">
            <img src="/assets/blob2.png" alt="Blob 2" className="blob-image" />
          </div>
          <div className="faq-text faq-front">Why don't the other platforms do this?</div>
          <div className="faq-text faq-back">Most platforms rely on viral content. The platforms benefit from the leaks, because it drives engagement. Financial vetting reduces the number of followers. FS creates a different kind of fansite experience for both viewers and content creators.</div>
        </div>
        <div className="faq-circle" id="circle3">
          <div className="image-container">
            <img src="/assets/blob3.png" alt="Blob 3" className="blob-image" />
          </div>
          <div className="faq-text faq-front">How much will I receive if my content leaks?</div>
          <div className="faq-text faq-back">Tiered access levels range from a $1000 penalty up to a $100,000 penalty. You will be able to set the access level on your content. FS will take 20% of any damages collected from your infringers and the rest will payout to you.</div>
        </div>
        <div className="faq-circle" id="circle4">
          <div className="image-container">
            <img src="/assets/blob4.png" alt="Blob 4" className="blob-image" />
          </div>
          <div className="faq-text faq-front">Is this anonymous?</div>
          <div className="faq-text faq-back">FS treats your anonymyty with utmost importance. We will never reveal your details to anyone else unless compelled by court order. Any legal action against your content infringers will be undertaken under our name not yours.</div>
        </div>
        <div className="faq-circle" id="circle5">
          <div className="image-container">
            <img src="/assets/blob5.png" alt="Blob 5" className="blob-image" />
          </div>
          <div className="faq-text faq-front">Will my friends and family find my account?</div>
          <div className="faq-text faq-back">FS has several options to limit the risk of known parties finding your account. If you want, you can set your profile to approved followers only. You would only accept followers after seeing their name. Second, if you link your contacts we use artificial intelligence to keep people who might have mutual friends apart. </div>
        </div>
      </div>
      <div className="media-enforcement-section">
        <h2>Corporate producers like Tushy enforce their rights ... Now you can too.</h2>
        <h3>anti-piracy in the media</h3>
          <div className="media-links">
            <motion.div className="cloud" animate={{ x: [0, 300, -300, 0] }} transition={{ duration: 60, repeat: Infinity }}>
              <a href="https://www.latimes.com/business/story/2023-12-12/column-this-porn-company-makes-millions-by-shaming-porn-consumers" className="media-link">
                LA Times: How a Hollywood Producer Monetizes Infringement Claims
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, -350, 350, 0] }} transition={{ duration: 80, repeat: Infinity }}>
              <a href="https://torrentfreak.com/strike-3-filed-a-record-number-of-piracy-lawsuits-this-year-221227/" className="media-link">
                TorrentFreak: Record Piracy Lawsuits Filed by Strike 3
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, 400, -400, 0] }} transition={{ duration: 70, repeat: Infinity }}>
              <a href="https://www.civilbeat.org/2024/06/porn-producer-is-waging-war-in-honolulu-against-alleged-copyright-pirates/" className="media-link">
                Civil Beat: The Battle Against Copyright Pirates
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, -300, 300, 0] }} transition={{ duration: 85, repeat: Infinity }}>
              <a href="https://www.theregister.com/2021/06/21/court_of_justice_telenet_bittorrent_ruling/" className="media-link">
                The Register: EU Highes Court Gives the OK to Copyright Enforcement
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, 350, -350, 0] }} transition={{ duration: 65, repeat: Infinity }}>
              <a href="https://www.globallegalpost.com/news/adult-movie-pirating-disputes-see-fox-rothschild-top-us-copyright-case-ranking-21930840" className="media-link">
                Global Legal Post: BigLaw Firm Fox Rothschild Dominates Copyright Cases
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, -400, 400, 0] }} transition={{ duration: 75, repeat: Infinity }}>
              <a href="https://finance.yahoo.com/news/fox-rothschild-partner-faces-sanctions-033407715.html" className="media-link">
                Yahoo Finance: Sanctions in Copyright Enforcement
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, 300, -300, 0] }} transition={{ duration: 90, repeat: Infinity }}>
              <a href="https://natlawreview.com/article/strike-3-saga-turning-bittorrent-downloads-copyright-infringement-settlement-1" className="media-link">
                NatLaw Review: The Strike 3 Holdings Saga
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, -350, 350, 0] }} transition={{ duration: 100, repeat: Infinity }}>
              <a href="https://localnewsmatters.org/2024/02/14/caught-in-a-bad-romance-bay-area-still-leads-rest-of-the-nation-in-porn-copyright-litigation/" className="media-link">
                Local News Matters: Bay Area’s Lead in Video Copyright Litigation
              </a>
            </motion.div>
            <motion.div className="cloud" animate={{ x: [0, 300, -300, 0] }} transition={{ duration: 70, repeat: Infinity }}>
              <a href="https://torrentfreak.com/copyright-troll-has-already-filed-over-1000-piracy-lawsuits-this-year-220607/" className="media-link">
                TorrentFreak: Copyright Troll’s Massive Legal Campaign
              </a>
            </motion.div>
          </div>


      </div>

    </>
  );
};

export default HomePage;
